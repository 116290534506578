/* eslint-disable prettier/prettier */
<template>
  <v-main>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-container>
              <v-row style="font-size: 12px; margin-top: 7px">
                <v-icon size="medium" color="success" style="margin-top: 10px"
                  >mdi-account</v-icon
                >
                <span style="margin-top: 10px" @click="dialog = true"
                  >个训:{{ count.single }}</span
                >
              </v-row>
              <v-row
                style="
                  font-size: 12px;
                  margin-top: 1px;
                  min-width: 340px;
                  max-width: 690px;
                "
              >
                <v-icon size="medium" color="teal lighten-3"
                  >mdi-account-multiple</v-icon
                >
                <span style="margin-top: 11px" @click="dialog = true"
                  >集体:{{ count.group }}</span
                >
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-select
                  style="width: 78px; margin-bottom: -14px"
                  v-model="selectedStudent"
                  :items="student_list"
                  item-text="nick_name"
                  item-value="ID"
                  return-object
                  single-line
                  @change="getRecordByStudentId(selectedStudent)"
                ></v-select>
              </v-row>
            </v-container>
          </v-toolbar>
        </v-sheet>
        <v-sheet :height="calenderHight">
          <v-calendar
            ref="calendar"
            v-model="focus"
            type="month"
            color="primary"
            :show-month-on-first="false"
            :events="events"
            :event-color="getEventColor"
            @change="updateRange"
            :event-more="more"
            @click:event="showType"
            :first-interval="8"
            :event-height="15"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="270px" tile>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.type"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.name"></span>
                <v-spacer></v-spacer>
                <span>{{ selectedEvent.createAt | transferTime }}</span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="ma-1"
                  color="grey"
                  plain
                  @click="selectedOpen = false"
                >
                  <v-icon left>mdi-cancel</v-icon>取消
                </v-btn>
                <v-btn depressed color="error" plain @click="revoke">
                  <v-icon left>mdi-undo-variant</v-icon> 撤销
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
        <v-snackbar
          v-model="snackbar.status"
          centered
          :color="snackbar.color"
          :timeout="snackbar.timeout"
        >
          {{ snackbar.msg }}
        </v-snackbar>
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialog"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark>课时统计</v-toolbar>
              <v-card-text style="margin-top: 10px">
                <v-row>
                  <v-col  cols="3" sm="2">
                    <span class="total-count" >{{ count.total }}</span>
                    <p class="total-head">总记</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <span class="total">{{ count.single }}</span>
                    <p class="total-head">个训</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <span class="total">{{ count.group }}</span>
                    <p class="total-head">集体</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <span class="total">{{ count.connect }}</span>
                    <p class="total-head">衔接</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <span class="total">{{ count.subsidiary }}</span>
                    <p class="total-head">辅课</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <span class="total">{{ count.shadow }}</span>
                    <p class="total-head">影子</p>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-simple-table >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              姓名
                            </th>
                            <th class="text-left">
                              个训
                            </th>
                            <th class="text-left">
                              集体
                            </th>
                            <th class="text-left">
                              衔接
                            </th>
                            <th class="text-left">
                              辅课
                            </th>
                            <th class="text-left">
                              影子
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in teacher_detail"
                            :key="item.name"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.single }}</td>
                            <td>{{ item.group }}</td>
                            <td>{{ item.connect }}</td>
                            <td>{{ item.subsidiary }}</td>
                            <td>{{ item.shadow }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">关闭</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
    <Footerbar />
  </v-main>
</template>

<script>
import moment from "moment";

import Footerbar from "@/layout/Footerbar";
import { studentList, getRecord, deleteRecord } from "@/api";
import { utc2beijing, statusFilter, statusTransfer } from "@/utils";

export default {
  components: {
    Footerbar,
  },
  data: () => ({
    student_list: [],
    student: {},
    signData: [],
    focus: "",
    dialog: false,
    snackbar: {
      status: false,
      msg: "",
      color: "",
      timeout: 1000,
    },
    calenderHight: 530,
    more: false,
    selectedStudent: undefined,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    calendarMonth: "",
    count: {
      total: 0,
      single: 0,
      group: 0,
      shadow: 0,
      connect: 0,
      subsidiary: 0,
    },
    user_id: parseInt(localStorage.getItem("id")),
    teacher_detail:[],
    events: [],
  }),
  filters: {
    transferTime(utcTime) {
      let time = utc2beijing(utcTime);
      return time;
    },
  },
  mounted() {
    this.getStudentList();
    this.$refs.calendar.checkChange();
  },

  methods: {
    revoke() {
      if (this.selectedEvent.user_id !== this.user_id) {
        this.selectedOpen = false;
        this.snackbar.status = true;

        this.snackbar.msg = "不能撤销其他用户打卡记录";
        this.snackbar.color = "warning";
        return;
      }

      deleteRecord({ id: this.selectedEvent.id }).then((res) => {
        this.selectedOpen = false;
        if (res.data.code == "200") {
          this.snackbar.status = true;
          this.snackbar.msg = "撤销成功";
          this.snackbar.color = "success";

          this.getRecordByStudentId(this.student);
        } else {
          this.snackbar.status = true;
          this.snackbar.msg = res.data.msg;
          this.snackbar.color = "warning";
        }
      });
    },
    showType({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getRecordByStudentId(n, move) {
      if (this.calendarMonth != "") {
        this.months(move);
        var month = this.calendarMonth;
      } else {
        month = this.months(move);
      }
      let startTime = month.startOf("month").format("YYYY-MM-DD HH:mm:ss.SSS"); //月初
      let endTime = month.endOf("month").format("YYYY-MM-DD HH:mm:ss.SSS"); //月末

      getRecord({
        student_id: parseInt(n.ID),
        start_at: startTime,
        end_at: endTime,
      }).then((res) => {
        this.signData = res.data.data.records;
        this.count.group = res.data.data.detail.group;

        this.count.single = res.data.data.detail.single;
        this.count.total = res.data.data.detail.total;
        this.count.shadow = res.data.data.detail.shadow;
        this.count.connect = res.data.data.detail.connect;
        this.count.subsidiary = res.data.data.detail.subsidiary;
        this.teacher_detail = res.data.data.detail.user_detail

        this.student = n;

        this.updateRange();
      });
    },
    months(move) {
      if (move == "prev") {
        var month = moment(this.$refs.calendar._data.lastStart.date).subtract(
          1,
          "months"
        );
        this.calendarMonth = month;
      } else if (move == "next") {
        month = moment(this.$refs.calendar._data.lastStart.date).add(
          1,
          "months"
        );
        this.calendarMonth = month;
      } else {
        month = moment();
      }

      return month;
    },
    getStudentList() {
      studentList({}).then((res) => {
        this.student_list = res.data.data;
        this.student = this.student_list[this.student_list.length - 1];
        this.selectedStudent = this.student;

        let month = moment();

        let startTime = month
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss.SSS"); //月初
        let endTime = month.endOf("month").format("YYYY-MM-DD HH:mm:ss.SSS"); //月末

        getRecord({
          student_id: parseInt(this.student.ID),
          start_at: startTime,
          end_at: endTime,
        }).then((res) => {
          this.signData = res.data.data.records;
          this.count.group = res.data.data.detail.group;

          this.count.single = res.data.data.detail.single;
          this.count.total = res.data.data.detail.total;
          this.count.shadow = res.data.data.detail.shadow;
          this.count.connect = res.data.data.detail.connect;
          this.count.subsidiary = res.data.data.detail.subsidiary;
          this.teacher_detail = res.data.data.detail.user_detail
          this.updateRange();
        });
      });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
      this.getRecordByStudentId(this.student, "prev");
    },
    next() {
      this.$refs.calendar.next();
      this.getRecordByStudentId(this.student, "next");
    },
    updateRange() {
      const events = [];
      const allDate = [];
      var count = new Map();

      for (let i = 0; i < this.signData.length; i++) {
        const create_time = new Date(this.signData[i].created_at * 1000);
        if (
          allDate.indexOf(
            moment(this.signData[i].created_at * 1000).format("MMDD")
          ) !== -1
        ) {
          let n = count.get(
            moment(this.signData[i].created_at * 1000).format("MMDD")
          );
          n += 1;
          count.set(
            moment(this.signData[i].created_at * 1000).format("MMDD"),
            n
          );
        } else {
          allDate.push(
            moment(this.signData[i].created_at * 1000).format("MMDD")
          );
          count.set(
            moment(this.signData[i].created_at * 1000).format("MMDD"),
            1
          );
        }

        events.push({
          name: this.signData[i].teacher_name,
          start: create_time,
          details: this.signData[i].status > 0 ? "出勤" : "缺勤",
          createAt: this.signData[i].created_at,
          color: statusFilter(this.signData[i].status),
          timed: false,
          type: statusTransfer(this.signData[i].status),
          id: this.signData[i].id,
          user_id: this.signData[i].user_id,
        });
      }

      this.events = events;
      var maxCount = Math.max.apply(Math, Array.from(count.values()));

      if (maxCount <= 1) {
        this.calenderHight = 520;
      } else if (maxCount <= 4) {
        this.calenderHight = 580;
      } else if (maxCount <= 6) {
        this.calenderHight = 760;
      } else if (maxCount > 6) {
        this.calenderHight = 1030;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  max-width: 73px;
}

.total {
  font-size: 22px;
  font-weight: 600;
}
.total-head {
  font-size: 12px;
}
.total-count {
  font-size: 27px;
  font-weight: 600;
  color: #1976d2;
}
</style>
